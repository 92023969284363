<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text">{{ isEditModel ? 'Edit Entry' : 'Add New Entry' }}</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <ac-select
                label="List Type"
                v-model="listType"
                :items="['Public', 'Private']"
                class="company-select"
              />
            </v-col>
            <v-col cols="6">
              <ac-select
                label="Entry Type"
                v-model="entryType"
                :items="['Restricted', 'Watchlist']"
                class="company-select"
                :disabled="isEditModel"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="security"
                label="Security"
                :search-input.sync="search"
                :loading="loading.security"
                :items="securityOptions"
                item-text="name"
                hide-details="auto"
                outlined
                dense
                no-filter
                :rules="[v => !!v || 'Please Select Security']"
                @input="selectSecurity"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <ac-input v-model="symbol" label="Ticker Symbol or Stock Code" outlined />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-input v-model="exchange" label="Exchange" outlined />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-date-picker v-model="startDate" label="Start Date" outlined />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-date-picker v-model="expiryDate" label="Expiry Date" outlined />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Close" color="error" outlined @click="onClose(true)" />
          <ac-button title="Submit" color="success" :loading="loading.submit" @click="onSubmit" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'UpsertRestrictedEntryModal',
  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
    editItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      loading: {
        submit: false,
        security: false,
      },
      listType: 'Public',
      entryType: 'Restricted',
      security: null,
      symbol: null,
      exchange: null,
      startDate: null,
      expiryDate: null,
      search: null,
    }
  },
  computed: {
    ...mapGetters('paDealing', ['securityList']),
    securityOptions() {
      if (this.checkObject(this.editItem)) {
        const { exchange, security, symbol } = this.editItem
        const options = [
          {
            name: security,
            symbol,
            exchange: exchange,
          },
        ]
        return options.concat(this.securityList)
      } else {
        return this.securityList
      }
    },
    isEditModel() {
      return this.checkObject(this.editItem)
    },
    isRestrictedItem() {
      return this.entryType === 'Restricted'
    },
  },
  watch: {
    search(val) {
      val && val !== this.security && this.getSecurityList(val)
    },
    value: {
      handler(val) {
        if (val) {
          if (this.checkObject(this.editItem)) {
            const { exchange, expiryDate, listType, security, startDate, symbol, type } =
              this.editItem
            this.listType = listType
            this.entryType = type
            this.security = security
            this.symbol = symbol
            this.exchange = exchange
            this.startDate = startDate === '-' ? null : startDate
            this.expiryDate = expiryDate === '-' ? null : expiryDate
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('paDealing', [
      'fetchSecurityList',
      'createRestrictedListItem',
      'createWatchListItem',
      'updateRestrictedListItem',
      'updateWatchListItem',
    ]),
    ...mapMutations('paDealing', ['SET_SECURITY_LIST']),

    async onSubmit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading.submit = true
        const {
          companyId,
          listType,
          entryType,
          security,
          symbol,
          exchange,
          startDate,
          expiryDate,
        } = this
        if (!this.isEditModel) {
          const payload = {
            companyID: companyId,
            content: {
              listType,
              type: entryType,
              security,
              symbol,
              exchange,
              startDate,
              expiryDate,
            },
          }

          const { success, message } =
            entryType === 'Restricted'
              ? await this.createRestrictedListItem(payload)
              : await this.createWatchListItem(payload)

          if (success) {
            this.loading.submit = false
            this.AcNotify({
              type: 'success',
              message,
              position: 'bottom',
            })
            this.onClose()
          } else {
            this.loading.submit = false
            this.AcNotify({
              type: 'error',
              message,
              position: 'bottom',
            })
          }
        } else {
          const { id } = this.editItem

          const payload = {
            recordID: id,
            content: {
              listType,
              type: entryType,
              security,
              symbol,
              exchange,
              startDate,
              expiryDate,
            },
          }

          const { success, message } =
            entryType === 'Restricted'
              ? await this.updateRestrictedListItem(payload)
              : await this.updateWatchListItem(payload)

          if (success) {
            this.loading.submit = false
            this.AcNotify({
              type: 'success',
              message,
              position: 'bottom',
            })
            this.onClose()
          } else {
            this.loading.submit = false
            this.AcNotify({
              type: 'error',
              message,
              position: 'bottom',
            })
          }
        }
      }
    },
    onClose(preventRefetch) {
      this.listType = 'Public'
      this.entryType = 'Restricted'
      this.symbol = null
      this.exchange = null
      this.startDate = null
      this.expiryDate = null
      this.SET_SECURITY_LIST([])
      this.$refs.form.resetValidation()
      this.$emit('input', false)
      this.$emit('close', !preventRefetch)
    },
    async getSecurityList(val) {
      this.loading.security = true
      await this.fetchSecurityList(val)
      this.loading.security = false
    },
    selectSecurity(val) {
      const { securityList } = this
      const securityObj = securityList.find(obj => {
        return obj.name === val
      })
      this.symbol = securityObj.symbol
      this.exchange = securityObj.exch
    },
  },
}
</script>

<style lang="scss" scoped></style>
